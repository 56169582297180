<template lang='pug'>
	.wizard(@click.stop :class='{"active": isShown, "step-2": step == 2, "small-height": step == 2 && !isMobile && windowInnerHeight < 730}' v-if='complete != 100 && !hideOnPages')
		.wizard__info(v-if="step == 1 && !isMobile")
			p.b3.mb-1.wizard__text {{ $t('wizard.completeSetup') }}
			.d-flex.flex-md-column.align-items-center
				p.b1.mb-md-1.mr-2.mr-md-auto.wizard__text {{ `${complete}%` }}
				<svg class="wizard__circle" xmlns="http://www.w3.org/2000/svg" width="183" height="16" viewBox="0 0 183 16" fill="none">
					<path opacity="0.2" d="M8 6H179" stroke="#0B7BE8" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 20" d="M8 6H36" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 33" d="M8 6H60" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 40" d="M8 6H72" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 60" d="M8 6H108" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 66" d="M8 6H120" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 80" d="M8 6H144" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					<path v-if="complete == 100" d="M8 6H179" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
				</svg>
			.btn.btn-yellow.btn-small.mt-1.mt-md-2.wizard__button(@click='step++') {{ $t('wizard.complete') }}

		.wizard__info.justify-content-between.wizard__info--mobile(v-if="step == 1 && isMobile")
			a.d-xs-block.d-lg-none(href='#' v-b-modal.modalMobileMenu)
				mobile-menu-icon.mb-1.mr-2
			.d-flex.flex-column
				p.b3.mb-1.wizard__text {{ $t('wizard.completeSetup') }}
				.d-flex.flex-md-column.align-items-center
					<svg class="wizard__circle" xmlns="http://www.w3.org/2000/svg" width="183" height="16" viewBox="0 0 183 16" fill="none">
						<path opacity="0.2" d="M8 6H179" stroke="#0B7BE8" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 20" d="M8 6H36" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 33" d="M8 6H60" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 40" d="M8 6H72" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 60" d="M8 6H108" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 66" d="M8 6H120" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 80" d="M8 6H144" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
						<path v-if="complete == 100" d="M8 6H179" stroke="#5BE0A0" stroke-width="8" stroke-linecap="round"/>
					</svg>
			.btn.btn-yellow.btn-small.ml-2.wizard__button(@click="step++")
				svg(xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none")
					path(d="M11.212 4.25L16.2498 9.5L11.212 14.75" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
					line(x1="15.0497" y1="9.7738" x2="3.74988" y2="9.7738" stroke="#52A7F9" stroke-width="2" stroke-linecap="round")

		.wizard__list(v-show="step == 2")
			img.wizard__list-img(src="/assets/img/current/confetti.png")
			.wizard__close(v-if="!isMobile" @click="close")
				svg(xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none")
					path(d="M4.36542 4.36542L12.1036 12.1036" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
					path(d="M4.36542 12.1036L12.1036 4.36542" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
			.d-flex.align-items-center.mb-3(v-if="isMobile")
				svg.mr-2(@click="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
					path(d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008" stroke="#F7F7F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				h3.wizard__list-title {{ $t('wizard.welcome') }}
			.d-flex.align-items-center.align-items-md-end
				.d-flex.flex-column.mb-3
					h3.wizard__list-title(v-if="!isMobile") {{ $t('wizard.welcome') }}
					p.mt-4.mb-0.wizard__disclaimer(:class="isMobile ? 'b4' : 'b3'") {{ $t('wizard.disclaimer') }}
				.wizard__diagram.mb-2.mb-md-4.mr-2.mr-md-4
					<svg id="diagram" xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122" fill="none">
						<circle opacity="0.2" r="53" cx="50%" cy="50%" stroke="#0B7BE8" stroke-width="10"/>
						<circle v-if="complete == 20" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="264" stroke-dasharray="330" stroke-linecap="round"/>
						<circle v-if="complete == 33" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="224" stroke-dasharray="330" stroke-linecap="round"/>
						<circle v-if="complete == 40" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="198" stroke-dasharray="330" stroke-linecap="round"/>
						<circle v-if="complete == 60" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="132" stroke-dasharray="330" stroke-linecap="round"/>
						<circle v-if="complete == 66" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="120" stroke-dasharray="330" stroke-linecap="round"/>
						<circle v-if="complete == 80" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="66" stroke-dasharray="330" stroke-linecap="round"/>
						<circle v-if="complete == 100" d="M8 6H179" r="53" cx="50%" cy="50%" stroke="#5BE0A0" stroke-width="15" stroke-dashoffset="0" stroke-dasharray="330" stroke-linecap="round"/>
					</svg>
					h2.wizard__complete {{ `${complete}%` }}

			.d-flex.flex-column.wizard__links
				.d-flex.align-items-center.wizard__item(v-if="!project.isNeuro()" @click="$router.push({name: 'project_settings', query: {tab: 1}}), close()")
					i.fontello.fontello-icon-mountain
					span.b3.mr-auto {{ $t('wizard.steps[0]') }}
					span.wizard__success(v-if="projectHasData")
					span.btn.btn-primary.wizard__btn-plus(v-if="!projectHasData")
						span.m-0.plus-primary
				.d-flex.align-items-center.wizard__item(v-if="!project.isNeuro()" @click="$router.push({name: 'project_resources'}), close()")
					i.fontello.fontello-icon-channels-and-chats
					span.b3.mr-auto {{ $t('wizard.steps[1]') }}
					span.wizard__success(v-if="projectHasResouces")
					span.btn.btn-primary.wizard__btn-plus(v-if="!projectHasResouces")
						span.m-0.plus-primary
				.d-flex.align-items-center.wizard__item(v-if="project.isNeuro()" @click="goToBot()")
					.d-flex.justify-content-center.align-items-center.rounded-circle.wizard__bot
						svg(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
							path(d='M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
							path(fill-rule='evenodd' clip-rule='evenodd' d='M15.5 9.75C14.6716 9.75 14 9.07843 14 8.25C14 7.42157 14.6716 6.75 15.5 6.75C16.3284 6.75 17 7.42157 17 8.25C17 9.07843 16.3284 9.75 15.5 9.75Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
							path(fill-rule='evenodd' clip-rule='evenodd' d='M8.5 9.75C7.67157 9.75 7 9.07843 7 8.25C7 7.42157 7.67157 6.75 8.5 6.75C9.32843 6.75 10 7.42157 10 8.25C10 9.07843 9.32843 9.75 8.5 9.75Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
							path(d='M15.6 13.3008C16.1 13.3008 16.5 13.7008 16.5 14.2008C16.5 16.6908 14.49 18.7008 12 18.7008C9.51 18.7008 7.5 16.6908 7.5 14.2008C7.5 13.7008 7.9 13.3008 8.4 13.3008H15.6Z' stroke='#52A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					span.b3.mr-auto {{ $t('wizard.steps[5]') }}
					span.wizard__success(v-if="project && project.bot")
					span.btn.btn-primary.wizard__btn-plus(v-if="project && !project.bot")
						span.m-0.plus-primary
				.d-flex.align-items-center.wizard__item(v-if="!project.isNeuro()" @click="goToTariffs()")
					i.fontello.fontello-icon-plans
					span.b3.mr-auto {{ $t('wizard.steps[2]') }}
					svg.cursor-pointer(v-if='!projectHasResouces' :id="!isMobile ? `tooltip-resources` : ''" @click.stop="isMobile ? $bvModal.show(`tooltip-resources`) : ''" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
						circle(cx="12" cy="12" r="12" :fill="isDarkColor ? '#2F2F2F' : '#FFF'")
						path(d="M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z" fill="#52A7F9")
					span.wizard__success(v-if="projectHasPlans")
					span.wizard__disable(v-if="!projectHasResouces")
					span.btn.btn-primary.wizard__btn-plus(v-if="!projectHasPlans")
						span.m-0.plus-primary
				.d-flex.align-items-center.wizard__item(@click="$router.push('/profile'), close()")
					i.fontello.fontello-icon-user-edit
					span.b3.mr-auto {{ $t('wizard.steps[3]') }}
					span.wizard__success(v-if="isProfileFilled")
					span.btn.btn-primary.wizard__btn-plus(v-if="!isProfileFilled")
						span.m-0.plus-primary
				.d-flex.align-items-center.wizard__item(@click="goToFinance()")
					i.fontello.fontello-icon-accounts
					span.b3.mr-auto {{ $t('wizard.steps[4]') }}
					svg.cursor-pointer(v-if='!isProfileFilled' :id="!isMobile ? `tooltip-profile` : ''" @click.stop="isMobile ? $bvModal.show(`tooltip-profile`) : ''" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
						circle(cx="12" cy="12" r="12" :fill="isDarkColor ? '#2F2F2F' : '#FFF'")
						path(d="M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z" fill="#52A7F9")
					span.wizard__success(v-if="hasFilledPaymentAccount")
					span.wizard__disable(v-if="!isProfileFilled")
					span.btn.btn-primary.wizard__btn-plus(v-if="!hasFilledPaymentAccount")
						span.m-0.plus-primary

		modal-tooltip(
			:id="`tooltip-resources`"
			:title="$t('promotions.attention')"
			:text="$t('wizard.addResources')"
			:textMobile="$t('wizard.addResources') + '.'"
		)

		modal-tooltip(
			:id="`tooltip-profile`"
			:title="$t('promotions.attention')"
			:text="$t('wizard.addProfile')"
			:textMobile="$t('wizard.addProfile')"
		)
</template>

<script>
import ModalTooltip from '@/components/Modals/ModalTooltip';
import { MobileMenuIcon } from '@/components/Svg';
import { mapGetters } from 'vuex';

export default {
	name: 'ModalWizard',
	components: {
		ModalTooltip,
		MobileMenuIcon
	},
	data: () => ({
		isShown: false,
		step: 1,
		windowInnerHeight: null,
		scrollbarWidth: 0
	}),
	computed: {
		...mapGetters({
			project : 'project/opened',
		}),
		isProfileFilled() {
			if ( !this.me ) return false;

			return this.me.hasFilledAccount();
		},
		hasFilledPaymentAccount() {
			if ( !this.me ) return false;

			return this.me.hasFilledPaymentAccounts();
		},
		projectHasData() {
			if ( !this.project ) return false;

			return this.project.description != null && this.project.description.length > 0 && this.project.image != null;
		},
		projectHasPlans() {
			if ( !this.project ) return false;

			return this.project.plans.length > 0 && !this.project.isAllPlansArchived() && this.project.somePlansHasResourceActive();
		},
		projectHasResouces() {
			if ( !this.project ) return false;

			return this.project.canSeeMenuPlans();
		},
		complete() {
			let total = 0;
			let stepPercent = this.project && this.project.isNeuro() ? 33 : 20;

			if ( this.project && this.project.isNeuro() && this.project.bot ) total += stepPercent;

			if ( this.isProfileFilled ) total += stepPercent;
			if ( this.projectHasData && !this.project.isNeuro() ) total += stepPercent;
			if ( this.projectHasPlans && !this.project.isNeuro() ) total += stepPercent;
			if ( this.projectHasResouces && !this.project.isNeuro() ) total += stepPercent;
			if ( this.hasFilledPaymentAccount ) total += stepPercent;

			if ( total == 99 ) total = 100;

			return total;
		},
		hideOnPages() {
			return this.$route.name == 'profile' || this.$route.name == 'finance';
		}
	},
	created() {
		this.isShown = true;
		this.windowInnerHeight = window.innerHeight;
		window.addEventListener('resize', () => {
			this.windowInnerHeight = window.innerHeight;
		})
		window.addEventListener('click', e => {
			if(this.step == 2 && this.isShown == true && !this.isMobile) {
				this.close();
			}
		})
	},
	mounted() {
		this.getScrollbarWidth();
	},

	destroyed() {
		if ( this.complete == 100 && this.$route.name != 'finance' )
			this.$bvModal.show('modalwizardsuccess');
	},
	methods : {
		close() {
			this.step = 1;
			document.body.classList.remove("modal-open");
			document.body.classList.remove("overlay");
			document.querySelector('body').style.paddingRight = 0;
		},

		goToTariffs() {
			if ( !this.projectHasResouces ) {
				return this.isMobile ? this.$bvModal.show(`tooltip-resources`) : '';
			}
			this.$router.push({name: 'project_plans'})
			this.close();
		},
		goToFinance() {
			if ( !this.isProfileFilled ) {
				return this.isMobile ? this.$bvModal.show(`tooltip-profile`) : '';
			}

			this.$router.push('/finance');
			this.close();
		},
		goToBot() {
			this.$router.push({name: 'project_bot', params: {id: this.project.id}});
			this.close();
		},
		getScrollbarWidth() {
			// Creating invisible container
			const outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll'; // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement('div');
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			this.scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);
		}
	},
	watch: {
		step(v) {
			if(v == 2 && this.isMobile) {
				document.body.classList.add("modal-open");
			} else {
				document.body.classList.remove("modal-open");
			}

			if(v == 2 && !this.isMobile) {
				document.body.classList.add("overlay");
				this.getScrollbarWidth();
				document.querySelector('body').style.paddingRight = this.scrollbarWidth + 'px';
			} else {
				this.getScrollbarWidth();
				document.querySelector('body').style.paddingRight = 0;
				document.body.classList.remove("overlay");

			}
		},

		complete(v) {
			if ( v == 100 ) {
				this.$bvModal.show('modalwizardsuccess');
				this.close();
			}
		}
	}
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.wizard {
	z-index: 9999;
	position: fixed;
	display: none;
	flex-direction: column;
	bottom: 24px;
	left: 100vw;
	width: max-content;
	box-shadow: var(--box-shadow);
	overflow: hidden;
	transform: translateX(calc(-100% - 32px));

	@include respond-below(sm) {
		z-index: 1039;
		bottom: auto;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		transform: translateX(0);
	}

	&.active {
		display:flex
	}

	&.step-2 {
		@include respond-below(sm) {
			z-index: 1040;
			position: fixed;
			top: 0;
			bottom: 0;
			overflow-y: auto;

			/* Скрываем scrollbar для IE, Edge и Firefox */
			-ms-overflow-style: none;  /* IE и Edge */
			scrollbar-width: none;  /* Firefox */

			/* Скрываем scrollbar для Chrome, Safari и Opera */
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&.small-height {
		.wizard__list {
			padding: 16px 16px 0;
		}

		.wizard__disclaimer {
			padding: 16px 16px 16px 52px;
		}

		.wizard__diagram {
			margin: 0 0 0 16px;
		}

		.wizard__close {
			top: 8px;
			right: 8px;
			width: 32px;
			height: 32px;
		}

		.wizard__links {
			padding: 18px 16px;
			margin: 16px -16px 0;
		}

		.wizard__item {
			padding: 10px 21px;
			margin-bottom: 12px;

			i {
				width: 36px;
				height: 36px;
				font-size: 20px;
			}
		}
	}

	&__bot {
		width: 44px;
		height: 44px;
		margin-right: 10px;
		background-color: var(--foreground-color);
	}

	&__info {
		position: relative;
		padding: 15px 25px;
		width: 230px;
		background-color: var(--brand-primary);
		border-radius: var(--border-radius-rounded);
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: -18px;
			right: -46px;
			width: 90px;
			height: 90px;
			background-image: url('/assets/img/current/list.png');
			background-repeat: no-repeat;
			background-size: 100%;
		}

		&--mobile {
			@include respond-below(sm) {
				display: flex;
				align-items: flex-end;
				padding: 16px 12px 13px;
				width: 100%;
				height: 68px;
				border-top-right-radius: 0;
				border-top-left-radius: 0;

				&::before {
					content: none;
				}
			}
		}
	}

	&__text {
		color: var(--foreground-color);
	}

	&__circle {
		margin-left: -3px;
	}

	&__button {
		@include respond-below(sm) {
			width: 111px;
			max-height: 33px;
		}
	}

	&__list {
		position: relative;
		padding: 50px 26px 0;
		width: 500px;
		background: linear-gradient(var(--brand-primary) 50%, var(--foreground-color) 50%, );
		border-radius: var(--border-radius-rounded);

		@include respond-below(sm) {
			padding: 17px 12px 0;
			width: 100%;
			height: 100%;
			border-radius: 0;
		}
	}

	&__list-img {
		position: absolute;
		top: -22px;
		right: 96px;
		width: 96px;
		height: 96px;
		transform: rotate(23.281deg);

		@include respond-below(sm) {
			top: -32px;
			right: -32px;
			transform: rotate(58.782deg);
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.05);
		transition: var(--animation-time-short);
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}
	}

	&__list-title {
		color: #fff;
	}

	&__disclaimer {
		position: relative;
		padding: 20px 20px 20px 52px;
		font-size: 14px;
		line-height: 22px;
		color: #fff;
		border-radius: var(--border-radius-rounded);
		background-color: #4E9FED;

		&::before {
			content: '';
			position: absolute;
			top: 20px;
			left: 20px;
			width: 24px;
			height: 24px;
			color: #fff;
			mask-image: url('/assets/img/icons/info-square.svg');
			background-color: var(--brand-warning);
			background-repeat: no-repeat;
		}

		@include respond-below(sm) {
			padding: 10px 2px 10px 48px;

			&::before {
				top: 8px;
				left: 16px;
			}
		}
	}

	&__diagram {
		position: relative;
		width: 116px;
		height: 116px;
		margin: 12px 0 0 16px;

		svg {
			transform: rotate(-90deg);
		}

		@include respond-below(sm) {
			margin: 0 0 0 16px;
		}
	}

	&__complete {
		position: absolute;
		top: 52%;
		left: 55%;
		color: #fff;
		transform: translate(-50%, -50%);
	}

	&__links {
		position: relative;
		z-index: 1;
		padding: 30px 28px 34px;
		height: 100%;
		background-color: var(--foreground-color);
		border-radius: 40px 40px 8px 8px;
		margin: 20px -26px 0;

		@include respond-below(sm) {
			min-height: 416px;
			height: max-content;
			padding: 23px 12px;
			border-radius: 20px 20px 0 0;
			margin: 16px -12px 0;
		}
	}

	&__item {
		padding: 12px 21px;
		border-radius: var(--border-radius-rounded);
		background-color: var(--bg-back);
		transition: var(--animation-time-short);
		margin-bottom: 15px;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}

		&:hover,
		&:active {
			background-color: var(--foreground-color);
			box-shadow: var(--card-box-hover-shadow);

			i {
				background-color: var(--background-color);
			}

			.wizard__btn-plus {
				opacity: 0.5;
			}

			.wizard__bot {
				background-color: var(--background-color);
			}
		}

		&:has(.wizard__success) {
			pointer-events: none;
		}
		&:has(.wizard__disable) {
			background-color: var(--bg-back);
			box-shadow: none;

			i {
				background-color: var(--foreground-color);
			}

			.wizard__btn-plus,
			.wizard__btn-plus:hover {
				opacity: 0.5;
				background-color: var(--brand-primary);
				pointer-events: none;
			}
		}

		i {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 44px;
			min-width: 44px;
			height: 44px;
			min-height: 44px;
			font-size: 24px;
			border-radius: 50%;
			background-color: var(--foreground-color);
			transition: var(--animation-time-short);
			margin-right: 10px;
			color: var(--brand-primary);
		}
	}

	&__btn-plus {
		width: 35px;
		max-width: 35px;
		min-width: 35px;
		height: 35px;
		max-height: 35px;
		min-height: 35px;
		padding: 8.5px;
		margin-left: 12px;
		transition: var(--animation-time-short);
		box-shadow: none !important;
	}

	&__success {
		position: relative;
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		border-radius: 50%;
		background-color: var(--brand-success);
		margin: 0 5px 0 12px;

		&::after {
			content: '';
			position: absolute;
			top: 51%;
			left: 50%;
			width: 100%;
			height: 100%;
			mask-image: url('/assets/img/icons/check.svg');
			mask-repeat: no-repeat;
			mask-size: 60%;
			mask-position: center;
			background-color: var(--background-color);
			transform: translate(-50%, -50%);
		}
	}

	.menu-icon-line {
		border-color: #fff !important;
	}
}
</style>
